import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

type NavLink = {
  title: string;
  link: string;
};

function NavBarItem(props: { link: NavLink }) {
  return (
    <a
      href={props.link.link}
      className= {(window.location.pathname === props.link.link || (window.location.pathname === "/" && props.link.link === "/about")) ? "nav-link-focused" : "nav-link"}
      style={{
        padding: 2,
        width: "100%",
        textAlign: "center",
        textDecoration: "none",
        fontWeight: "bold",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.link.title}
    </a>
  );
}

export default function NavBar() {
  const headerStyle: React.CSSProperties = {
    fontFamily: "RaBali",
    fontSize: "3em",
    color: "white",
    margin: 0,
  };

  const navLinks: NavLink[] = [
    {
      title: "About Me",
      link: "/about",
    },
    {
      title: "Drawings",
      link: "/drawings",
    },
    {
      title: "Paintings",
      link: "/paintings",
    },
    {
      title: "Artist Statement",
      link: "/statement",
    },
    {
      title: "Contact Me",
      link: "/contact-me",
    },
    {
      title: "Instagram",
      link: "https://www.instagram.com/castillocreate/",
    },
  ];

  return (
    <nav style={{ backgroundColor: "#000000" }}>
      <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
        <h1 style={headerStyle}>Castillo Create</h1>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
        }}
        className="grid md:grid-cols-6"
      >
        {navLinks.map((link, i) => {
          return (
            <div className="md:flex" style={{ width: "100%" }}>
              <NavBarItem link={link} />
              <div style={{ border: ".5px solid #000" }}></div>
            </div>
          );
        })}
      </div>
    </nav>
  );
}
