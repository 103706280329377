import "./App.css";
import NavBar from "./components/navbar";
import Home from "./components/home";
import Footer from "./components/footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactMe from "./components/contactMe";
import CommissionInformation from "./components/commissionInformation";
import Paintings from "./components/paintings";
import GraphicWorks from "./components/graphicWorks";
import ArtistStatement from "./components/artistStatement";

function App() {
  return (
    <div style={{ height: "100%" }}>
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<Home/>} />
          <Route path="/contact-me" element={<ContactMe/>} />
          <Route path="/commission" element={<CommissionInformation/>} />
          <Route path="/commission" element={<CommissionInformation/>} />
          <Route path="/paintings" element={<Paintings/>} />
          <Route path="/drawings" element={<GraphicWorks/>} />
          <Route path="/statement" element={<ArtistStatement/>} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
