export default function ContactMe() {
  return (
    <div className="p-10">
      <h1 className="font-bold text-4xl mb-10">Contact Me</h1>
      <p>
        If you would like to discuss a commission, purchase a piece, or to just say hi, you can contact me direcly at: 
      </p>
      <p className="mb-5"> 
        <a href="mailto:havefaith.fmc@gmail.com">havefaith.fmc@gmail.com</a>
      </p>
      <p>
        I'd also like to invite you to connect with my art account on Instagram where I regularly upload pictures of my work:
      </p>
      <p className="mb-5"> 
        <a href="https://instagram.com/castillocreate">@castillocreate on Instagram</a>
      </p>
    </div>
  );
}
