import LayingPerson from "./static/images/drawings/laying-person.jpg"
import LayingWoman from "./static/images/drawings/laying-woman.jpg"
import SittingPerson from "./static/images/drawings/sitting-person.jpg"
import ThinkingPerson from "./static/images/drawings/thinking-person.jpg"
import Glitch from "./static/images/drawings/glitch.jpg"
import WomansFace from "./static/images/drawings/womans-face.jpg"
import Reburta from "./static/images/drawings/reburta.jpg"
import StudioLook from "./static/images/a-look-into-my-studio.jpg"

import WorkInProgress from "./static/images/paintings/wip-oil-paint.jpg"
import Mastercopy from "./static/images/paintings/mastercopy-oil-paint.jpg"

type ArtPiece = {
        src: string,
        width: number,
        height: number,
        title: string,
        medium: string,
        price: string,
        date: string,
        dimensions: string,
        description: string
      };

const paintings: ArtPiece[] = [
  {
    src: WorkInProgress,
    width: 25,
    height: 18,
    title: "Work in Progress",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2023",
    dimensions: "Unknown", 
    description: ""
  },
  {
    src: Mastercopy,
    width: 25,
    height: 18,
    title: "Mastercopy",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2023",
    dimensions: "Unknown", 
    description: ""
  },
];

const drawings: ArtPiece[] = [
  {
    src: StudioLook,
    width: 30,
    height: 20,
    title: "A look into my studio",
    medium: "Various",
    price: "Not for sale",
    date: "2023",
    dimensions: "Various", 
    description: "A look at several work in progress pieces currently in my studio"
  },
  {
    src: LayingPerson,
    width: 25,
    height: 18,
    title: "Laying Person",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: SittingPerson,
    width: 18,
    height: 15,
    title: "Sitting Person",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "15in x 18in", 
    description: ""
  },
  {
    src: ThinkingPerson,
    width: 25,
    height: 18,
    title: "Thinking Person",
    medium: "Chalk",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: LayingWoman,
    width: 25,
    height: 18,
    title: "Laying Woman",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: Glitch,
    width: 20,
    height: 18,
    title: "Glitch",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 20in", 
    description: ""
  },
  {
    src: WomansFace,
    width: 15,
    height: 11,
    title: "Woman's Face",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "11in x 15in", 
    description: ""
  },
  {
    src: Reburta,
    width: 25,
    height: 18,
    title: "Reburta",
    medium: "Chalk",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
];

export { paintings, drawings }
