import ImageGrid from "./imageGrid";
import {paintings} from "../artData"

export default function Paintings() {

  return (
    <div className="p-10">
        <h1 className="font-bold text-4xl">Paintings</h1>
      <p className="mb-10"> 
        A collection of my oil paintings.
      </p>
      <ImageGrid photos={paintings} />
    </div>
  );
}
