import { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import { Modal, ModalGateway } from "react-images";

export default function ImageGrid(props: { photos: any}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event: any, { photo, index }: any) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
  <Gallery photos={props.photos} onClick={openLightbox} />
      {/* @ts-ignore */}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
                <div className="overflow-scroll max-h-screen">
                  <div className="md:flex" style={{ justifySelf: "center",  justifyContent: "center" }} onClick={(e) => { if (e.target === e.currentTarget) {closeLightbox()}}}>
                    <img src={props.photos[currentImage].src} style={{ maxWidth: "50%"}} className="hidden md:inline"/>
                    <img src={props.photos[currentImage].src} className="md:hidden inline"/>
                    <div
                      className="bg-white md:m-5 p-5 max-w-full md:max-w-screen-sm"
                      style={{ height: "max-content" }}
                    >
                      <div onClick={closeLightbox} style={{backgroundColor: "#eee", borderRadius: 5, padding: 3, float: "right", alignItems: "center", display: "flex", justifyContent: "center", marginTop: -10}}>
                        <p className="text-black" style={{color: "#444444", cursor: "pointer", margin: 0, padding: 0}}> Close </p>
                      </div>
                      <h1 className="text-4xl text-black">{props.photos[currentImage].title}</h1>
                      <p className="text-black">
                        <b>Medium:</b> {props.photos[currentImage].medium}
                      </p>
                      <p className="text-black">
                        <b>Dimensions:</b> {props.photos[currentImage].dimensions}
                      </p>
                      <p className="text-black">
                        <b>Date Created:</b> {props.photos[currentImage].date}
                      </p>
                      <p className="text-black">
                        <b>Price:</b> {props.photos[currentImage].price}
                      </p>
                      <p className="text-black">
                        {props.photos[currentImage].description}
                      </p>
                      <p className="mt-5 text-black">
                        Artwork is Copyright &copy; Faith Castillo, 2023
                      </p>
                    </div>
                  </div>
                </div>
            </Modal>
          ) : null}
        </ModalGateway>
        </div>
  );
}
