// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  color: #eee;
  background-color: #888c6d;
  transition: 0.5s;
}

.nav-link:hover {
  color: #888c6d;
  background-color: #eee;
}

.nav-link-focused {
  color: #888c6d;
  background-color: #eee;
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB","sourcesContent":[".nav-link {\n  color: #eee;\n  background-color: #888c6d;\n  transition: 0.5s;\n}\n\n.nav-link:hover {\n  color: #888c6d;\n  background-color: #eee;\n}\n\n.nav-link-focused {\n  color: #888c6d;\n  background-color: #eee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
