import ImageGrid from "./imageGrid";
import {drawings, paintings} from "../artData";

export default function GraphicWorks() {

  return (
    <div className="p-10">
        <h1 className="font-bold text-4xl">Drawings</h1>
      <p className="mb-10"> 
        This is a collection of my drawings. They consist moslty of figure drawings
        in charcoal or chalk formats.
      </p>
      <ImageGrid photos={drawings} />
    </div>
  );
}
