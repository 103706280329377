export default function CommissionInformation() {
  return (
    <div className="p-2">
        <h1 className="font-bold text-4xl">Commission Information</h1>
      <p> 
        If you would like to contact me for any reason, the best way to 
        do so is by sending me an E-Mail at <a href="mailto:myemail@example.com">myemail@example.com</a>
      </p>
    </div>
  );
}
