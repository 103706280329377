import FaithPicture from "../static/images/headshot.jpeg";
import "./home.css";
import ImageGrid from "./imageGrid";
import {drawings, paintings} from "../artData";

export default function Home() {
  
  let photos = paintings.concat(drawings);

  return (
    <div
      className="grid-cols-6 grid text-white"
      style={{ backgroundColor: "#000", height: "100%" }}
    >
      <div className="lg:col-span-1 md:col-span-2 col-span-6 p-2">
        <h1 className="font-bold text-4xl">Faith Castillo</h1>
        <p>Philadelphia, Pennsylvania</p>
        <div className="p-5">
          <img
            src={FaithPicture}
            className="object-cover mb-5"
            style={{ height: 300, width: "100%", objectPosition: "100% 10%" }}
          />
          <p>
            Faith Castillo is a rising artist who focuses on natural and surreal art. Originally from Austin, Texas, she currently attends Pennsylvania Academy of the Fine Arts in Philadelphia, Pennsylvania. With a deep appreciation for the natural world, Faith meticulously captures the delicate nuances of light and shadow in her figure drawings. A spark of surrealism ignites in her work, inviting viewers to delve into the depths of their own imagination and explore the mysteries that lie beyond the surface.
          </p>
        </div>
      </div>
      <div className="lg:col-span-5 md:col-span-4 col-span-6 p-10">
        <ImageGrid photos={photos} />
      </div>
    </div>
  );
}
