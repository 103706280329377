export default function ArtistStatement() {
  return (
    <div className="p-10">
      <h1 className="font-bold text-4xl mb-10">Artist Statement: Embracing the Beauty of Realism</h1>
      <p className="mb-5">
        Being an artist, the world of realism has always played a large part in their world. With very movement in their body, every movement in their eyes they continue to attempt to reach the summits of perfection in details, even value- every freckle, eyelash, hair holds unfathomable value to them,  unfortunately no amount of will ever reach satisfaction because in the end perfection is unattainable. 
In each work, they invite viewers to ponder the absolute and graphic dimension of the subject to bring more light and ideas to very sensitive memories, it feels as if every canvas shows their soul like a reflection on a mirror. 
In each piece, they invite viewers to pause and observe the world anew. They aim to evoke a sense of wonder and recognition, encouraging a deeper connection with the familiar and the extraordinary. Realism, to them, is not mere replication but a profound exploration of the stories that reside within the subjects they choose to portray.
</p>
<p className="mb-5">
Their artistic journey is a continuous quest for mastery, a commitment to refining their technique and perception. It is a journey marked by patience and discipline, as they strive to make each creation a testament to the power of precision and attention to detail.
</p>
<p className="mb-5">
From a young age, they were drawn to the tactile arts of charcoal drawing and sculpting. These early experiences have shaped their artistic sensibilities, infusing their work with a profound appreciation for form and dimension. Charcoal's rich, velvety textures and the physicality of sculpting have left an indelible mark on their artistic identity.
</p>
<p className="mb-5">
During their childhood, they experienced vivid and lucid dreams that often served as a wellspring of inspiration. These dreams, with their surreal landscapes and ethereal beings, continue to influence their work, infusing it with a touch of the otherworldly.
</p>
<p className="mb-5">
Growing up, they grappled with anxious tendencies, and it was during these challenging times that they discovered the therapeutic power of drawing. Art became a sanctuary, a means of coping with anxiety and finding solace in the act of creation. In each stroke of the pencil or brush, they found a source of calm and a way to express the complex emotions within.
</p>
<p className="mb-5">
Their journey into the world of art began when, as a child of nine, their uncle showed them a drawing they had made. It was a moment of revelation, witnessing the untapped potential and talent within their own family. However, as they grew older, they discovered that their uncle had never been able to fully explore his artistic potential. In his youth, the uncle had not had the opportunity to pursue an education, and the need to provide for his family meant that his artistic talents remained largely unfulfilled.
</p>
<p className="mb-5">
This realization deeply affected them and became a driving force behind their own artistic pursuits. They fell in love with art not only for its inherent beauty but also because it represented a profound connection to the untold stories of their family's past, stories marked by unfulfilled dreams and the impact of socio-economic challenges.
</p>
<p className="mb-5">
They are the first generation in their family to attend art school, let alone college. Their path to pursuing their passion for art has been marked by determination and hard work. Their dedication paid off, as they were awarded a full tuition scholarship to attend art school, a testament to their talent and perseverance.
</p>
<p className="mb-5">
In addition to their personal work, they have been fortunate to explore the world of commissioned art since middle school. Working with others to bring their visions to life has been a rewarding experience, allowing them to expand their creative horizons while connecting with people through art.
</p>
<p className="mb-5">
Furthermore, they have had the privilege of contributing to the visual tapestry of Marble Falls by creating two small murals for local shops. These murals have provided them with the opportunity to engage with the community and infuse public spaces with the beauty of realism.
</p>
<p className="mb-5">
Their art is also deeply rooted in their strong Hispanic and Native American heritage, which serves as a wellspring of inspiration. The cultural richness, traditions, and stories of their ancestors infuse their work with a unique perspective and a profound connection to the land and its people. Growing up in a Mexican Roman Catholic background has shaped them as the person they are today, for better or worse, instilling values of faith, community, and tradition that have both influenced and challenged their artistic journey.
</p>
<p className="mb-5">
Through the lens of realism, they hope to transcend the boundaries of mere representation, allowing viewers to experience the profound emotions and stories embedded in the art of the everyday. They are deeply grateful to share this journey with you, and they invite you to join them in exploring the profound beauty of the real world, one brushstroke at a time.
      </p>
    </div>
  );
}
